import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import "config/firebaseConfig";
//import AuthLayout from "layouts/Auth.js";
//import AdminLayout from "layouts/Admin.js";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import reducers from "./store/reducers";
import { setUser, setAppLoading } from "store/actions";
import { interceptorProvider } from "store/actions/api";
import { GET_USER, SubscribeToUser } from "store/queries/index.js";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "apollo-link-error";

import "assets/scss/fxpricezone.scss?v=1.9.0";
import SplashScreen from "views/Pages/SplashScreen";
import Utils from "utils/Utils";

/* const store = applyMiddleware(thunk)(createStore)(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
); */
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  console.log("operation is ", operation);
  if (networkError) {
    switch (networkError.message) {
      case "auth/id-token-expired":
        //TODO SYNC request to server, better with WS transport
        return console.log("accessTokenExpired");
      case "auth/argument-error":
        console.log("invalide token", networkError);

        return;
      // return store.commit('logout')
      default:
        console.log("default error", networkError);
      //   return forward(operation)
    }
  }
  if (graphQLErrors) {
    console.log("graphql error at index", graphQLErrors);
    return;
  }

  // return forward(operation)
});

const authMiddleware = {
  async applyMiddleware(operationOptions, next) {
    if (firebase.auth().currentUser) {
      operationOptions["token"] = await firebase
        .auth()
        .currentUser.getIdToken();
    } else {
      operationOptions["token"] = "";
    }
    next();
  },
};
const wsLink = new WebSocketLink({
  uri:
    // "ws://localhost:8080/graphql",
    // "wss://server.fxpricezone.com/graphql",
    // "ws://localhost:4000/graphql",
    // "ws://134.209.64.220/graphql",
    "wss://api.fxpricezone.com/graphql",
  options: {
    reconnect: true,
    connectionParams: {},
  },
});

wsLink.subscriptionClient.use([authMiddleware]);

const client = new ApolloClient({
  link: from([errorLink, wsLink]),
  cache: new InMemoryCache(),
  // connectToDevTools: false,
});

const store = applyMiddleware(thunk)(createStore)(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
interceptorProvider(store);

const hist = createBrowserHistory();

const delay = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
//window.store = store;
firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    if (!firebase.auth().currentUser.emailVerified) {
      if (AllowConfirmEmail()) {
        var email = firebase.auth().currentUser.email;
        localStorage.setItem("verificationEmailFlag", email);
        var actionCodeSettings = {
          url:
            "https://app.fxpricezone.com/auth/login-page?email=" +
            firebase.auth().currentUser.email,
        };
        firebase.auth().currentUser.sendEmailVerification(actionCodeSettings);
      }
      store.dispatch(setAppLoading(true));
      store.dispatch(setUser("unlisted"));
    } else {
      // store.dispatch(
      //   setUser({ email: firebase.auth().currentUser.email, role: "admin" })
      // );
      await client.subscribe({ query: SubscribeToUser }).subscribe({
        next(response) {
          console.log("subscription data is ", response);
          store.dispatch(setUser(response.data.user));
          return response;
        },
      });
      await delay(2000);
      await client
        .query({
          query: GET_USER,
        })
        .then((response) => {
          let { user } = response.data;

          store.dispatch(setUser(user));

          if (Utils.checkIfBaseSubscription(user)) {
            console.log("user has plan and is not canceled", user);
            store.dispatch(setAppLoading(false));
          } else {
            console.log("user has no plan or is canceled", user);
            store.dispatch(setAppLoading(true));
          }
        });
    }
    if (isAuthLink(window.location.pathname)) {
      hist.push({
        pathname: "/",
      });
    }
  } else {
    store.dispatch(setUser(null));
    client.resetStore();
    hist.push({
      pathname: "/auth/login-page",
    });
  }
});

const isAuthLink = (url) => {
  const authLinks = ["/login-page", "/register-page"];
  let index = authLinks.find((authLink) => url.search(authLink) !== -1);
  if (index) {
    return true;
  } else {
    return false;
  }
};

const AllowConfirmEmail = () => {
  var email = firebase.auth().currentUser.email;
  var storage = localStorage.getItem("verificationEmailFlag");
  if (storage) {
    if (storage === email) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const AuthLayout = lazy(() => import("./layouts/Auth"));
const AdminLayout = lazy(() => import("./layouts/Admin"));
ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Router history={hist}>
        <Suspense fallback={<SplashScreen></SplashScreen>}>
          <Switch>
            <Route path="/auth" component={AuthLayout} />
            <Route path="/app" component={AdminLayout} />
            <Redirect from="/" to="/app/dashboard" />
          </Switch>
        </Suspense>
      </Router>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);
